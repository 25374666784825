<template>
  <div class="container">
    <div class="row" style="width:60rem;">
      <div class="col-md-1 ml-auto">

      </div>

      <div class="col-md-7">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-register card-white">
              <template slot="header">
                <div class="header_container d-flex justify-content-between align-items-center">
                  <div>
                    <img class="card-img" src="img/card-info.png" alt="Card image" />
                    <img class="img-fluid logo_sax " src="img/saxlogwhitesmall.png" alt="Logo"
                      style="position: relative; z-index: 1; padding-top: 10px;padding-left: 5px;" />

                  </div>


                </div>

              </template>


              <ValidationProvider name="email" rules="email" mode="lazy" v-slot="{ passed, failed, errors }">
                <base-input :required="true" v-model="email" placeholder="Email"
                  addon-left-icon="tim-icons icon-email-85" type="text" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">

                </base-input>

              </ValidationProvider>

              <ValidationProvider name="password" rules="required|validPassword" mode="lazy" v-slot="{ errors }">
                <base-input :required="true" v-model="password" :placeholder="$t('login.password')"
                  addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                  :class="{ 'has-success': !errors.length, 'has-danger': errors.length }">
                </base-input>
              </ValidationProvider>
              <ValidationProvider name="confirm_password" rules="confirmed:password" mode="lazy"
                v-slot="{ passed, failed, errors }">
                <base-input :required="true" v-model="confirmPassword" :placeholder="$t('login.confirmpassword')"
                  addon-left-icon="tim-icons icon-lock-circle" type="password" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <!-- <ValidationProvider name="SerialNumber" rules="uppercase" v-slot="{ passed, failed, errors }"> -->
              <ValidationProvider name="SerialNumber" mode="lazy" v-slot="{ passed, failed, errors }">
                <base-input :required="true" v-model="SerialNumber" :placeholder="$t('login.serial Number')"
                  addon-left-icon="tim-icons icon-double-right" type="text" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <!-- <ValidationProvider name="SerialNumber1" rules="uppercase" v-slot="{ passed, failed, errors }"> -->
              <ValidationProvider name="SerialNumber1" mode="lazy" v-slot="{ passed, failed, errors }">
                <base-input :required="false" v-model="SerialNumber1" :placeholder="$t('login.serialnumber1')"
                  addon-left-icon="tim-icons icon-double-right" type="text" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <!-- <ValidationProvider name="SerialNumbe2" rules="uppercase" v-slot="{ passed, failed, errors }"> -->
              <ValidationProvider name="SerialNumbe2" mode="lazy" v-slot="{ passed, failed, errors }">
                <base-input :required="false" v-model="SerialNumber2" :placeholder="$t('login.serialnumber2')"
                  addon-left-icon="tim-icons icon-double-right" type="text" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider name="phone" mode="lazy" rules="required|phone" v-slot="{ errors }">
                <base-input :required="true" :placeholder="$t('login.phone')" v-model="phone" inputmode="numeric"
                  pattern="\d*" addon-left-icon="tim-icons icon-mobile" type="text" :error="errors[0]"
                  :class="{ 'has-success': !errors.length, 'has-danger': errors.length }">
                </base-input>
              </ValidationProvider>

              <select style="color: black; background-color: white" class="rounded form-select w-100"
                aria-label="Default select example" mode="lazy" v-model="selectedOption" :required="true">
                <option value="" disabled selected hidden>{{ $t('login.selector') }}</option>
                <option v-for="(country, index) in $t('login.countries')" :key="index" :value="index">{{ country }}
                </option>
              </select>
              <div class="d-flex justify-content-between align-items-center">
                <base-checkbox class="text-left" v-model="checkboxChecked">
                  {{ $t('login.agree') }}
                </base-checkbox>
                <select style="color: black; background-color: white; margin-top: 1rem;" class="form-select rounded"
                  aria-label="Default select example" v-model="selectedLanguage" @change="changeLanguage">
                  <option selected value="de">DE</option>
                  <option value="en">EN</option>
                  <!-- <option value="pl">PL</option> -->

                </select>
              </div>
              <base-button :disabled="!checkboxChecked" native-type="submit" slot="footer" type="info" round block
                size="lg">
                {{ $t('login.register') }}
              </base-button>
            </card>
          </form>
        </ValidationObserver>
      </div>
      <div class="col-md-1 ml-auto">

      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useApi } from "@/modules/api";
import { store } from "@/store";
import { BaseCheckbox } from "src/components";
import { extend } from "vee-validate";
import { required, email, alpha_num, confirmed } from "vee-validate/dist/rules";
import i18n from "@/i18n";
extend("email", email);
extend("required", required);
extend("alpha_num", alpha_num);
extend("confirmed", confirmed);
extend('uppercase', {
  validate: value => value === value.toUpperCase(),
  message: 'The {_field_} field must be in uppercase'
});
extend('validPassword', {
  params: [],
  validate(value) {
    // Regex explanation:
    // ^                 # start of string
    // (?=.*[a-z])       # at least one lowercase letter
    // (?=.*[A-Z])       # at least one uppercase letter
    // (?=.*\d)          # at least one digit
    // (?=.*[@$!%*?&§/=#+-."'{}()[\]~^|:;,<>\\_])   # at least one special character
    // .{8,}             # at least 8 characters total
    // $                 # end of string
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&§/=#+-."'{}()[\]~^|:;,<>\\_]).{8,}$/;
    return regex.test(value);
  },
  message: () => i18n.t("login.passwordError"),
});
extend('phone', {
  validate(value) {
    const regex = /^0\d{6,14}$/;
    return regex.test(value);
  },
  message: () => i18n.t("login.phoneError"),
});
export default {
  components: {
    BaseCheckbox,
  },
  data() {
    return {
      // selectedLanguage: 'de',
      selectedOption: '',
      checkboxChecked: false,
      isSuccess: false,
      email: "",

      password: "",
      confirmPassword: "",
      SerialNumber: "",
      SerialNumber1: "",
      SerialNumber2: "",
      phone: "",
    };
  },
  created() {
    if (localStorage.getItem("languageSelected")) {
      this.selectedLanguage = localStorage.getItem("languageSelected");
    } else {
      this.selectedLanguage = "de";
    }
    this.changeLanguage();
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem("languageSelected", this.selectedLanguage);
    },
    setErrorMessages(response) {
      this.errorMessages = [];
      if (typeof response === 'object' && response !== null) {
        Object.keys(response).forEach((key) => {
          const value = response[key];
          if (Array.isArray(value) && value.length > 0) {
            let message = value[0];
            if (typeof message === 'string' && message.startsWith("{") && message.endsWith("}")) {
              message = message.substring(2, message.length - 2);
              message = message.replace(/^"|"$/g, '');
              this.errorMessages.push(message);
            } else {
              this.errorMessages.push(message);
            }
          }else if (typeof value === 'string') {
            this.errorMessages.push(value);
          }
        });
      }


    },



    handleSelection() {
      if (this.selectedOption === '') {
        this.selectedOption = null;
      }
    },
    submit() {
      const selectedCountryIndex = this.selectedOption;
      const selectedCountry = i18n.t('login.countries')[selectedCountryIndex];
      const data = {
        email: this.email,

        sn: this.SerialNumber,
        sn1: this.SerialNumber1,
        sn2: this.SerialNumber2,
        password: this.password,
        passwordConfirm: this.confirmPassword,
        city: selectedCountry,
        phone: this.phone

      }

      this.isSuccess = true;
      useApi
        .post("/api/auth/register", data)
        .then(() => {

          Swal.fire({
            title: '',
            text: i18n.t('login.RegistrationSuccess'),
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#285891",
          })

          // this.rest();
        })
        .catch((error) => {
          // this.rest();
          const errM = this.setErrorMessages(error)
          const message = `${i18n.t('login.RegistrationFailed')}<br> ${i18n.t(this.errorMessages)}`
          Swal.fire({

            title: '',
            html: message,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#285891",
          })
        });
    },
    rest() {
      this.email = '';
      this.SerialNumber = null;
      this.SerialNumber1 = null;
      this.SerialNumber2 = null;
      this.password = null;
      this.confirmPassword = null;
      this.phone = null;

    }
  },
  validations: {
    password: {
      required,
    },
    confirmPassword: {
      required,
      confirmed: confirmed, // Import and use the confirmed rule
    },
    // ... other validation rules for your fields
  },

};
</script>
<style>
.sweetButton {
  background-color: #285891;

}

@media (max-width: 576px) {
  .logo_sax {
    width: 150px;
    /* Adjust the width for small screens */
  }



  .container {
    display: flex !important;
    justify-content: center !important;
  }
}

@media (min-width: 576px) {
  @media (max-width: 992px) {
    .logo_sax {
      width: 180px;
      /* Adjust the width for medium screens */
    }
  }
}

@media (min-width: 992px) {
  .logo_sax {
    width: 200px;
    /* Adjust the width for large screens */
  }
}
</style>
